import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import Home from "./pages/home/home.jsx";
import LocalizeSeuTransporte from "./pages/localize-seu-transporte/localize-seu-transporte";
import EscolhaAMelhorRota from "./pages/escolha-a-melhor-rota/escolha-a-melhor-rota";
import DefinaASuaRotina from "./pages/defina-a-sua-rotina/defina-a-sua-rotina";
import HowToUse from "./pages/como-usar/como-usar.jsx";
import AnuncieConosco from "./pages/anuncie-conosco/AnuncieConosco";
import ErrorPage from "./pages/error-page/error-page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/localize-o-seu-transporte",
    element: <LocalizeSeuTransporte />,
  },
  {
    path: "/escolha-a-melhor-rota",
    element: <EscolhaAMelhorRota />,
  },
  {
    path: "/defina-a-sua-rotina",
    element: <DefinaASuaRotina />,
  },
  {
    path: "/como-usar",
    element: <HowToUse />,
  },
  {
    path: "/anuncie-no-app",
    element: <AnuncieConosco />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
