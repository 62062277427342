import { useRouteError } from "react-router-dom";
import Header from "../../components/header/header";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div style={{width: '100vw', position: 'absolute', top: 70}}>
        <Header />
      </div>
      <h1>Oops!</h1>
      <p>Desculpe, o seguinte erro ocorreu: </p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
