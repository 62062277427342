import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Download from "../../components/download_section/download";
import "./localize-seu-transporte.css";
import celImg from "../../assets/img-celular.png";
import logoBranco from "../../assets/logo-branco.png";
import { isAndroid, isIOS } from "react-device-detect";

const LocalizeSeuTransporte = () => {
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header
        key={downloadPopup}
        popupController={firstRender ? false : true}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          marginTop: 70,
        }}
      >
        <div className="cel-div-loc">
          <div className="cel-div-first-loc">
            <div className="cel-img-div-loc">
              <img alt="foto de um aparelho celular" src={celImg} />
            </div>

            <div className="cel-text-div-loc">
              <p
                style={{ fontSize: "2rem", marginBottom: 10, paddingBottom: 0 }}
              >
                Saiba a localização exata do seu transporte
              </p>
              <div className="cel-img-div-loc-mobile">
                <img alt="foto de um aparelho celular" src={celImg} />
              </div>
              <p style={{ fontSize: "0.8rem", marginTop: 5 }}>
                Por meio de GPS a Take consegue saber o local exato de onde o
                seu transporte da SPTRANS se encontra, assim, você consegue
                chegar a tempo sem precisar ficar esperando no ponto.
              </p>
            </div>
          </div>

          <div className="cel-div-second-loc">
            <div>
              <div className="cel-div-second-text-loc">
                <p
                  style={{
                    fontSize: "2rem",
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  Evite desbloquear o seu celular para ter acesso às informações
                </p>
              </div>

              <div className="cel-div-second-text-paragraph-loc">
                <div className="cel-div-second-text-paragraph-first-loc">
                  <p style={{ fontSize: "0.8rem" }}>
                    Quando você inicia uma viagem com a Take, enviaremos uma
                    notificação para você saber em quanto tempo o seu transporte
                    chega, assim, você não precisa desbloquear o seu celular
                    para checar a informação
                  </p>
                </div>

                <div className="notification-div-loc">
                  <div>
                    <div className="logo-small-div-loc">
                      <img alt="logo take a bus" src={logoBranco} />
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 10,
                            color: "white",
                            marginRight: 12,
                          }}
                        >
                          Take a Bus
                        </span>

                        <span style={{ fontSize: 8, color: "white" }}>
                          Há 15 min.
                        </span>
                      </div>

                      <span
                        style={{
                          fontSize: 10,
                          color: "white",
                          width: "80%",
                        }}
                      >
                        Seu ônibus chega em 3 minutos!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isIOS ? (
            <a href="https://apps.apple.com/br/app/take-a-bus/id6449265591" target="_blank" style={{ textDecoration: "none" }}>
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : isAndroid ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : (
            <div
              className="download-app-rout"
              id="download_button_home"
              style={{ backgroundColor: "#f5aa22" }}
              onClick={() => {
                setDownloadPopup(!downloadPopup);
                setFirstRender(false);
              }}
            >
              <p id="buttom_text_home" style={{ color: "white" }}>
                Baixe o app agora
              </p>
            </div>
          )}
        </div>

        <div className="footer-div">
          <Download />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LocalizeSeuTransporte;
