import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const sendMailService = (body) => {
  const request = axios.create({
    baseURL: BASE_URL,
    data: body,
  });

  return request.post("/send-email", body);
};
