import { useEffect, useState } from "react";
import "./home.css";
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";
import Download from "../../components/download_section/download.jsx";
import bus from "../../assets/bus_icon_home.png";
import clock from "../../assets/clock_icon_home.png";
import trace_route_icon from "../../assets/route_icon_home.png";
import trace_route from "../../assets/trace_route_home.png";
import iphone_art from "../../assets/iphone_art_home.png";
import iphone_usage from "../../assets/image_home.png";
import arrow_right from "../../assets/arrow-right.png";
import Faq from "../../components/faq/faq.jsx";
import iphone_art_2 from "../../assets/iphone_art_2_home.png";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { isAndroid, isIOS } from "react-device-detect";

const section_content = [
  {
    tittle: "O que é a Take a Bus?",
    content:
      "Somos uma startup com a missão de revolucionar o transporte urbano, tornando-o mais seguro, descomplicado e amigável, através de integração de transportes e ferramentas inovadoras.",
  },
  {
    tittle: "Para que serve a Take a Bus",
    content:
      "A take a bus tem a função de melhorar a experiência de como você se locomove diariamente, com um mapeamento completo das linhas públicas de transporte e um conjunto de ferramentas pensadas a partir dos problemas enfrentados por utilizadores do transporte público.",
  },
];

const windowWidth = window.innerWidth;

function Home() {
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header key={downloadPopup} popupController={firstRender ? false : true}/>
      <div id="home">
        <div id="home_body">
          {windowWidth >= 1100 ? (
            <div id="section_home">
              <div id="section_1_column_1_home">
                <h1 id="tittle_home">Sua rota na palma da mão</h1>
                <div id="icons_section_home">
                  <img id="icon_home" src={bus} alt="ícone de ônibus" />
                  <p id="icon_text_home">
                    Saiba a localização do transporte em tempo real
                  </p>
                </div>
                <div id="icons_section_home">
                  <img
                    id="icon_home"
                    src={trace_route_icon}
                    alt="imagem de rota de transporte"
                  />
                  <p id="icon_text_home">Trace as melhores rotas</p>
                </div>
                <div id="icons_section_home">
                  <img id="icon_home" src={clock} alt="imagem de relógio" />
                  <p id="icon_text_home">
                    Chegue a tempo em todos os seus compromissos
                  </p>
                </div>
                {isIOS ? (
                  <a href="https://apps.apple.com/br/app/take-a-bus/id6449265591" target="_blank" style={{ textDecoration: "none" }}>
                    <div id="download_button_home">
                      <p id="buttom_text_home">Baixe o app agora</p>
                    </div>
                  </a>
                ) : isAndroid ? (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div id="download_button_home">
                      <p id="buttom_text_home">Baixe o app agora</p>
                    </div>
                  </a>
                ) : (
                  <div
                    id="download_button_home"
                    onClick={() => {
                      setDownloadPopup(!downloadPopup);
                      setFirstRender(false)
                    }}
                  >
                    <p id="buttom_text_home">Baixe o app agora</p>
                  </div>
                )}
              </div>
              <div id="section_1_column_2">
                <img
                  src={iphone_art}
                  id="iphone_art_home"
                  alt="Imagem de celular"
                />
              </div>
            </div>
          ) : (
            <div id="section_home">
              <div id="section_1_column_2">
                <img
                  src={iphone_art_2}
                  id="iphone_art_home"
                  alt="Imagem de celular"
                />
              </div>
              {isIOS ? (
                  <a href="https://apps.apple.com/br/app/take-a-bus/id6449265591" target="_blank" style={{ textDecoration: "none" }}>
                    <div id="download_button_home">
                      <p id="buttom_text_home">Baixe o app agora</p>
                    </div>
                  </a>
                ) : isAndroid ? (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div id="download_button_home">
                      <p id="buttom_text_home">Baixe o app agora</p>
                    </div>
                  </a>
                ) : (
                  <div
                    id="download_button_home"
                    onClick={() => {
                      setDownloadPopup(!downloadPopup);
                      setFirstRender(false)
                    }}
                  >
                    <p id="buttom_text_home">Baixe o app agora</p>
                  </div>
                )}
            </div>
          )}
          <div id="section_home" style={{ backgroundColor: "white" }}>
            <div id="section_2_column_1_home">
              <img
                id="iphone_usage_home"
                src={iphone_usage}
                alt="Imagem de celular em uso"
              />
            </div>
            <div id="section_2_column_2_home">
              <h2 id="section_2_tittle_home">Por que utilizar a Take a Bus?</h2>
              <div id="section_2_subtittle_highlight_home">
                <p id="section_2_subtittle_home">FACILIDADE</p>
              </div>
              <p id="section_2_subtittle_content_home">
                Tenha todas as informações do seu transporte em poucos cliques.
              </p>
              <div id="section_2_subtittle_highlight_home">
                <p id="section_2_subtittle_home">SEGURANÇA</p>
              </div>
              <p id="section_2_subtittle_content_home">
                Saiba exatamente onde o seu tranporte está e evite ficar
                esperando no ponto.
              </p>
              <div id="section_2_subtittle_highlight_home">
                <p id="section_2_subtittle_home">TEMPO</p>
              </div>
              <p id="section_2_subtittle_content_home">
                Saia no momento certo e passe mais tempo fazendo o que ama!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="section_home"
        style={{
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <div id="map_background_home">
          {windowWidth >= 801 ? (
            <div id="map_background_content_home">
              <div id="map_background_content_column_home">
                <div id="wrap_route_content_home">
                  <div id="route_background_home">
                    <div id="route_travel_info_home">
                      <div
                        id="travel_info_home"
                        style={{ backgroundColor: "#AE000A" }}
                      >
                        <p id="travel_info_text_home">Serviço 710</p>
                      </div>
                      <img
                        id="right_arrow_home"
                        src={arrow_right}
                        alt="imagem de flecha indicativa"
                      />
                      <div
                        id="travel_info_home"
                        style={{ backgroundColor: "#001486" }}
                      >
                        <p id="travel_info_text_home">107</p>
                      </div>
                    </div>
                    <p id="text_travel_info_home">Número de paradas: 4</p>
                    <p id="text_travel_info_home">Tempo de viagem: 36 min</p>
                    <p id="text_travel_info_home">Preço total: R$4,40</p>
                  </div>
                  <div id="route_background_home">
                    <div id="route_travel_info_home">
                      <div
                        id="travel_info_home"
                        style={{ backgroundColor: "#858688" }}
                      >
                        <p id="travel_info_text_home">03</p>
                      </div>
                      <img
                        id="right_arrow_home"
                        src={arrow_right}
                        alt="imagem de flecha indicativa"
                      />
                      <div
                        id="travel_info_home"
                        style={{ backgroundColor: "#F1AD4C" }}
                      >
                        <p id="travel_info_text_home">B63</p>
                      </div>
                      <img
                        id="right_arrow_home"
                        src={arrow_right}
                        alt="imagem de flecha indicativa"
                      />
                      <div
                        id="travel_info_home"
                        style={{ backgroundColor: "#73F182" }}
                      >
                        <p id="travel_info_text_home">I08</p>
                      </div>
                    </div>
                    <p id="text_travel_info_home">Número de paradas: 27</p>
                    <p id="text_travel_info_home">Tempo de viagem: 42 min</p>
                    <p id="text_travel_info_home">Preço total: R$13,20</p>
                  </div>
                </div>
                <div id="text_explanation_div_home">
                  <p id="text_explanation_home">Encontre o melhor caminho</p>
                </div>
              </div>
              <div id="map_background_content_column_home">
                <div id="wrap_route_content_home">
                  <img
                    id="trace_route_home"
                    src={trace_route}
                    alt="imagem de rota de transporte"
                  />
                  <p id="trace_route_text_home">Seu ônibus chega em 7 min</p>
                </div>
                <div id="text_explanation_div_home">
                  <p id="text_explanation_home">Informações do transporte</p>
                </div>
              </div>
              <div id="map_background_content_column_home">
                <div id="wrap_route_content_home">
                  <div id="route_background_2_home">
                    <p id="routine_time_home">09:00</p>
                    <div id="routine_line_home" />
                    <div id="routine_text_div_home">
                      <p id="routine_text_tittle_home">Trabalho</p>
                      <p id="routine_text_home">Av. Paulista, 1765</p>
                      <p id="routine_text_home">Diário</p>
                    </div>
                  </div>
                  <div id="route_background_2_home">
                    <p id="routine_time_home">17:00</p>
                    <div id="routine_line_home" />
                    <div id="routine_text_div_home">
                      <p id="routine_text_tittle_home">Consulta médica</p>
                      <p id="routine_text_home">Av. Paulista, 1070</p>
                      <p id="routine_text_home">Próx. Segunda</p>
                    </div>
                  </div>
                  <div id="route_background_2_home">
                    <p id="routine_time_home">20:00</p>
                    <div id="routine_line_home" />
                    <div id="routine_text_div_home">
                      <p id="routine_text_tittle_home">Academia</p>
                      <p id="routine_text_home">Av. Paulista, 1294</p>
                      <p id="routine_text_home">Diário</p>
                    </div>
                  </div>
                </div>
                <div id="text_explanation_div_home">
                  <p id="text_explanation_home">Defina sua rotina</p>
                </div>
              </div>
            </div>
          ) : (
            <div id="map_background_content_home">
              <Carousel variant="dark" id="home_carousel" autoPlay={false}>
                <Carousel.Item>
                  <div id="home_carousel_item">
                    <div id="map_background_content_column_home">
                      <div id="wrap_route_content_home">
                        <div id="route_background_home">
                          <div id="route_travel_info_home">
                            <div
                              id="travel_info_home"
                              style={{ backgroundColor: "#AE000A" }}
                            >
                              <p id="travel_info_text_home">Serviço 710</p>
                            </div>
                            <img
                              id="right_arrow_home"
                              src={arrow_right}
                              alt="imagem de flecha indicativa"
                            />
                            <div
                              id="travel_info_home"
                              style={{ backgroundColor: "#001486" }}
                            >
                              <p id="travel_info_text_home">107</p>
                            </div>
                          </div>
                          <p id="text_travel_info_home">Número de paradas: 4</p>
                          <p id="text_travel_info_home">
                            Tempo de viagem: 36 min
                          </p>
                          <p id="text_travel_info_home">Preço total: R$4,40</p>
                        </div>
                        <div id="route_background_home">
                          <div id="route_travel_info_home">
                            <div
                              id="travel_info_home"
                              style={{ backgroundColor: "#858688" }}
                            >
                              <p id="travel_info_text_home">03</p>
                            </div>
                            <img
                              id="right_arrow_home"
                              src={arrow_right}
                              alt="imagem de flecha indicativa"
                            />
                            <div
                              id="travel_info_home"
                              style={{ backgroundColor: "#F1AD4C" }}
                            >
                              <p id="travel_info_text_home">B63</p>
                            </div>
                            <img
                              id="right_arrow_home"
                              src={arrow_right}
                              alt="imagem de flecha indicativa"
                            />
                            <div
                              id="travel_info_home"
                              style={{ backgroundColor: "#73F182" }}
                            >
                              <p id="travel_info_text_home">I08</p>
                            </div>
                          </div>
                          <p id="text_travel_info_home">
                            Número de paradas: 27
                          </p>
                          <p id="text_travel_info_home">
                            Tempo de viagem: 42 min
                          </p>
                          <p id="text_travel_info_home">Preço total: R$13,20</p>
                        </div>
                      </div>
                      <div id="text_explanation_div_home">
                        <p id="text_explanation_home">
                          Encontre o melhor caminho
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div id="home_carousel_item">
                    <div id="map_background_content_column_home">
                      <div id="wrap_route_content_home">
                        <img
                          id="trace_route_home"
                          src={trace_route}
                          alt="imagem de rota de transporte"
                        />
                        <p id="trace_route_text_home">
                          Seu ônibus chega em 7 min
                        </p>
                      </div>
                      <div id="text_explanation_div_home">
                        <p id="text_explanation_home">
                          Informações do transporte
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div id="home_carousel_item">
                    <div id="map_background_content_column_home">
                      <div id="wrap_route_content_home">
                        <div id="route_background_2_home">
                          <p id="routine_time_home">09:00</p>
                          <div id="routine_line_home" />
                          <div id="routine_text_div_home">
                            <p id="routine_text_tittle_home">Trabalho</p>
                            <p id="routine_text_home">Av. Paulista, 1765</p>
                            <p id="routine_text_home">Diário</p>
                          </div>
                        </div>
                        <div id="route_background_2_home">
                          <p id="routine_time_home">17:00</p>
                          <div id="routine_line_home" />
                          <div id="routine_text_div_home">
                            <p id="routine_text_tittle_home">Consulta médica</p>
                            <p id="routine_text_home">Av. Paulista, 1070</p>
                            <p id="routine_text_home">Próx. Segunda</p>
                          </div>
                        </div>
                        <div id="route_background_2_home">
                          <p id="routine_time_home">20:00</p>
                          <div id="routine_line_home" />
                          <div id="routine_text_div_home">
                            <p id="routine_text_tittle_home">Academia</p>
                            <p id="routine_text_home">Av. Paulista, 1294</p>
                            <p id="routine_text_home">Diário</p>
                          </div>
                        </div>
                      </div>
                      <div id="text_explanation_div_home">
                        <p id="text_explanation_home">Defina sua rotina</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          )}
        </div>
      </div>
      <Download />
      <Faq tittle={"Perguntas frequentes"} section_content={section_content} />
      <Footer />
    </> 
  );
}

export default Home;
