import logo from "../../assets/logo.png";
import download_apple from "../../assets/appleStore.png";
import download_google from "../../assets/googleStore.png";
import "./download.css";

export default function Download() {
  return (
    <div id="background_download">
      <img id="logo_download" src={logo} alt="logo" />
      <p id="text_background">Baixe o app gratuitamente e venha ser taker!</p>
      <div id="download_buttons_background">
        <a
          href="https://apps.apple.com/br/app/take-a-bus/id6449265591"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <img
            id="download_store_button"
            src={download_apple}
            alt="Apple_store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <img
            id="download_store_button"
            src={download_google}
            alt="Google_store"
          />
        </a>
      </div>
    </div>
  );
}
