import "./footer.css";
import logoVertical from "../../assets/logoVertical.png";
import instagram from "../../assets/instagramLogo.png";
import linkedin from "../../assets/linkedinLogo.png";
import facebook from "../../assets/facebookLogo.png";
import youtube from "../../assets/youtubeLogo.png";
import { Link } from "react-router-dom";

const windowWidth = window.innerWidth;

export default function Footer() {
  return (
    <div id="background_footer">
      {windowWidth <= 650 ? (
        windowWidth <= 300 ? (
          <div id="logo_and_social_midia_footer">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <img id="logo_footer" src={logoVertical} />
            </Link>
            <div id="column_social_midia_footer">
              <div id="row_social_media_footer">
                <a
                  href="https://www.instagram.com/takeabusoficial/"
                  target="_blank"
                >
                  <img id="social_media_logo_footer" src={instagram} />
                </a>
                <a
                  href="https://www.linkedin.com/company/take-a-bus/about/"
                  target="_blank"
                >
                  <img id="social_media_logo_footer" src={linkedin} />
                </a>
              </div>
              {/* <div id="row_social_media_footer">
                <img id="social_media_logo_footer" src={youtube} />
                <img id="social_media_logo_footer" src={facebook} />
              </div> */}
            </div>
          </div>
        ) : (
          <div id="logo_and_social_midia_footer">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <img id="logo_footer" src={logoVertical} />
            </Link>
            <div id="row_social_media_footer">
              <a
                href="https://www.instagram.com/takeabusoficial/"
                target="_blank"
              >
                <img id="social_media_logo_footer" src={instagram} />
              </a>
              <a
                href="https://www.linkedin.com/company/take-a-bus/about/"
                target="_blank"
              >
                <img id="social_media_logo_footer" src={linkedin} />
              </a>
              {/* <img id="social_media_logo_footer" src={youtube} />
              <img id="social_media_logo_footer" src={facebook} /> */}
            </div>
          </div>
        )
      ) : (
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img id="logo_footer" src={logoVertical} />
        </Link>
      )}
      <div id="content_footer">
        <div id="column_content_footer">
          <p id="column_text_tittle_footer">Soluções para você</p>
          <Link
            to={"/escolha-a-melhor-rota"}
            style={{ textDecoration: "none" }}
          >
            <p id="column_text_footer">Escolha a melhor rota</p>
          </Link>
          <Link
            to={"/localize-o-seu-transporte"}
            style={{ textDecoration: "none" }}
          >
            <p id="column_text_footer">Localize o seu transporte</p>
          </Link>
          <Link to={"/defina-a-sua-rotina"} style={{ textDecoration: "none" }}>
            <p id="column_text_footer">Defina sua rotina</p>
          </Link>
        </div>
        <div id="column_content_footer">
          <Link to={"/como-usar"} style={{ textDecoration: "none" }}>
            <p id="column_text_tittle_footer">Como usar</p>
          </Link>
          {/* <Link to={"/quem-somos"} style={{ textDecoration: "none" }}>
          <p id="column_text_tittle_footer">Quem somos</p>
          </Link> */}
          <Link to={"/anuncie-no-app"} style={{ textDecoration: "none" }}>
            <p id="column_text_tittle_footer">Anuncie no App</p>
          </Link>
        </div>
        {/* <div id="column_content_footer">
          <p id="column_text_tittle_footer">Recursos</p>
          <p id="column_text_footer">Central de ajuda</p>
          <p id="column_text_footer">Fale com a gente</p>
          <p id="column_text_footer">Termos e condições</p>
        </div> */}
      </div>
      {windowWidth >= 650 ? (
        <div id="column_social_midia_footer">
          <div id="row_social_media_footer">
            <a
              href="https://www.instagram.com/takeabusoficial/"
              target="_blank"
            >
              <img id="social_media_logo_footer" src={instagram} />
            </a>
            <a
              href="https://www.linkedin.com/company/take-a-bus/about/"
              target="_blank"
            >
              <img id="social_media_logo_footer" src={linkedin} />
            </a>
          </div>
          {/* <div id="row_social_media_footer">
            <img id="social_media_logo_footer" src={youtube} />
            <img id="social_media_logo_footer" src={facebook} />
          </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
