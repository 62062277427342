import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Download from "../../components/download_section/download";
import "./escolha-a-melhor-rota.css";
import imgRoute from "../../assets/img-route.png";
import uberLogo from "../../assets/uber-logo.png";
import { FiArrowRight } from "react-icons/fi";
import { isAndroid, isIOS } from "react-device-detect";

const EscolhaAMelhorRota = () => {
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header
        key={downloadPopup}
        popupController={firstRender ? false : true}
      />
      <div style={{ display: "flex", flexDirection: "column", marginTop: 70 }}>
        <div className="cel-div1">
          <div className="cel-div-first1">
            <div className="img-div-escolha">
              <img alt="foto simulando uma rota" src={imgRoute} />
            </div>

            <div className="cel-text-div1">
              <p
                style={{ fontSize: "2rem", marginBottom: 0, paddingBottom: 0 }}
              >
                Escolha a rota que mais se adequa com a sua rotina
              </p>
              <div className="img-div-escolha-mobile">
                <img alt="foto simulando uma rota" src={imgRoute} />
              </div>
              <p style={{ fontSize: "1rem", marginTop: 5 }}>
                A Take disponibiliza diversas rotas para você se deslocar,
                escolha a que mais faz te agrade!
              </p>
            </div>
          </div>

          <div className="cel-div-second1">
            <div>
              <div className="cel-div-second-text1">
                <p
                  style={{
                    fontSize: "2rem",
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  Compare todas as formas de viajar e crie o seu futuro
                </p>
              </div>

              <div className="cel-div-second-text-paragraph1">
                <div className="cel-div-second-text-paragraph-first1">
                  <span style={{ fontSize: "1rem", marginTop: 5 }}>
                    <p>
                      Com a take, você sabe exatamente o quanto vai gastar em
                      cada uma das rotas, incluindo valores da Uber. Assim, você
                      tem o poder de escolher a rota mais rápida, barata com com
                      menos paradas.
                    </p>
                  </span>
                </div>

                <div className="bus-carrousel-div1">
                  <div className="bus-div1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <span
                        style={{
                          height: 20,
                          background: "#AE000A",
                          color: "white",
                          fontSize: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingRight: 4,
                          paddingLeft: 4,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Serviço 710
                      </span>
                      <FiArrowRight size={24} color="#F5AA22" />
                      <span
                        style={{
                          height: 20,
                          background: "#001486",
                          color: "white",
                          fontSize: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingRight: 4,
                          paddingLeft: 4,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        107
                      </span>
                    </div>

                    <div className="info-div1">
                      <span style={{ fontSize: 10 }}>Número de paradas: 4</span>
                      <span style={{ fontSize: 10 }}>
                        Tempo de viagem: 36 min
                      </span>
                      <span style={{ fontSize: 10 }}>Total: R$ 4,40</span>
                    </div>
                  </div>
                  <div className="bus-div1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <span
                        style={{
                          height: 20,
                          background: "#858688",
                          color: "white",
                          fontSize: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingRight: 4,
                          paddingLeft: 4,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        03
                      </span>
                      <FiArrowRight size={24} color="#F5AA22" />
                      <span
                        style={{
                          height: 20,
                          background: "#F1AD4C",
                          color: "white",
                          fontSize: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingRight: 4,
                          paddingLeft: 4,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        BC3
                      </span>
                      <FiArrowRight size={24} color="#F5AA22" />
                      <span
                        style={{
                          height: 20,
                          background: "#73F182",
                          color: "white",
                          fontSize: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingRight: 4,
                          paddingLeft: 4,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        I 08
                      </span>
                    </div>

                    <div className="info-div1">
                      <span style={{ fontSize: 10 }}>
                        Número de paradas: 27
                      </span>
                      <span style={{ fontSize: 10 }}>
                        Tempo de viagem: 42 min
                      </span>
                      <span style={{ fontSize: 10 }}>Total: R$ 4,40</span>
                    </div>
                  </div>
                  <div className="bus-div1">
                    <img
                      alt="uber logo"
                      src={uberLogo}
                      style={{ width: 40, marginLeft: 12 }}
                    />

                    <div className="info-div1">
                      <span style={{ fontSize: 10 }}>X: R$ 27,90</span>
                      <span style={{ fontSize: 10 }}>Black: R$ 35,40</span>
                      <span style={{ fontSize: 10 }}>
                        Tempo de Viagem: 42 min
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isIOS ? (
            <a href="https://apps.apple.com/br/app/take-a-bus/id6449265591" target="_blank" style={{ textDecoration: "none" }}>
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : isAndroid ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : (
            <div
              className="download-app-rout"
              id="download_button_home"
              style={{ backgroundColor: "#f5aa22", marginLeft: 0 }}
              onClick={() => {
                setDownloadPopup(!downloadPopup);
                setFirstRender(false);
              }}
            >
              <p id="buttom_text_home" style={{ color: "white" }}>
                Baixe o app agora
              </p>
            </div>
          )}
        </div>

        <div className="footer-div">
          <Download />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default EscolhaAMelhorRota;
