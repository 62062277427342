import { useState } from "react";
import arrow_down from "../../assets/arrow-down-black.png"
import "./faq.css";

export default function Faq({ tittle, section_content }) {
  const [expand, setExpand] = useState(section_content.map((i) => false));

  return (
    <div id="wrap_faq">
      <div id="faq_background">
        <h1 id="faq_tittle">{tittle}</h1>
        {section_content.map((item, index) => (
          <div key={"section_"+ index}>
            <div
              id="faq_section_background"
              onClick={() => {
                setExpand(
                  section_content.map((i, p) =>
                    p === index ? !expand[p] : false
                  )
                );
              }}
            >
              <p id="faq_section_tittle">{item.tittle}</p>
              <div id="faq_arrow_div">
                <img id="faq_arrow_down" src={arrow_down}/>
              </div>
            </div>
            {expand[index] && (
              <div id="faq_section_content">
                <p id="faq_section_text">{item.content}</p>
              </div>
            )}
            <div id="faq_division" />
          </div>
        ))}
      </div>
    </div>
  );
}
