import React, { useState } from "react";
import ModalResponse from "../Modal/Modal";
import { sendMailService } from "../../services/api/api";
import ReCAPTCHA from "react-google-recaptcha";

import "./contactForm.css";

const ContactForm = ({ textAreaPlaceHolder, hasCompany }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const cleanUpForm = () => {
    setTimeout(() => {
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setMessage("");
      setIsLoading(false);
    }, 1000);
  };

  const onChange = (value) => {
    if (value) {
      setIsVerified(true);
    }
  };

  const handleShowModal = (title, text) => {
    setModalTitle(title);
    setModalText(text);
    setModalShow(true);

    setTimeout(() => {
      setModalTitle("");
      setModalText("");
      setModalShow(false);
    }, 3500);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!name || !email) {
      handleShowModal(
        "Erro: ",
        "É necessário preencher os campos nome e email para continuar."
      );
      setIsLoading(false);
      return;
    }

    if (hasCompany && !company) {
      handleShowModal(
        "Erro: ",
        "É necessário prencher o campo empresa para continuar."
      );
      setIsLoading(false);
      return;
    }

    if (!isVerified) {
      handleShowModal(
        "Erro",
        "É necessário validar o captcha para prosseguir."
      );
      setIsLoading(false);
      return;
    }

    const emailBody = { name, email, phone, company, message };

    sendMailService(emailBody)
      .then(() => {
        handleShowModal(
          "Sucesso!",
          "E-mail enviado com sucesso! :) Em breve, um membro da equipe Take a Bus entrará em contato com você."
        );

        cleanUpForm();
      })
      .catch((e) => {
        handleShowModal("Ocorreu um erro", "Tente novamente mais tarde");
        cleanUpForm();
      });
  };

  return (
    <div className="main-contact-form-div">
      <ModalResponse
        text={modalText}
        title={modalTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <span className="form-title">
        Entre em contato para mais informações!
      </span>
      <form onSubmit={handleSubmit}>
        <span>(*) Campo Obrigatório</span>
        <div>
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            placeholder="Nome*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            placeholder="Email*"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phone">Telefone:</label>
          <input
            placeholder="Telefone"
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        {hasCompany && (
          <div>
            <label htmlFor="company">Empresa:</label>
            <input
              placeholder="Empresa*"
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        )}
        <div>
          <label htmlFor="message">Mensagem:</label>
          <textarea
            placeholder={textAreaPlaceHolder ?? "Deixe uma mensagem"}
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div
          style={{ margin: "0 auto", display: "flex", alignItems: "center", justifyContent: 'center', width: '80%' }}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
            onChange={onChange}
          />
        </div>
        <button
          className={isLoading ? "is-loading-button" : "contact-form-button"}
          type="submit"
          disable={String(isLoading)}
        >
          {isLoading ? "Enviando..." : "Enviar"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
