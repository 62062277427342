import Header from "../../components/header/header";
import Download from "../../components/download_section/download";
import Footer from "../../components/footer/footer";
import Faq from "../../components/faq/faq";
import Popup from "../../components/popup/popup";
import close_icon from "../../assets/close_icon.svg";
import ContactForm from "../../components/contact-form/ContactForm";
import "./como-usar.css";
import {
  section_content_1,
  section_content_2,
  section_content_3,
  section_content_4,
  section_content_5,
  section_content_6,
  favorite_routes_instructions,
  error_report_instructions,
  best_route_instructions,
  localize_seu_transporte_instructions,
  defina_rotina_instructions,
  download_app_instructions
} from "./utils";
import { useEffect, useState } from "react";

export default function HowToUse() {
  const [showPopup, setShowPopup] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <Header />
      {showPopup != null ? (
        <div id="popup_black_background">
          <Popup
            tittle={showPopup.tittle}
            gif_index={showPopup.gif_index}
            instructions={showPopup.instructions}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              id="popup_close_button"
              onClick={() => {
                setShowPopup(null);
              }}
            >
              <img
                id="popup_close_button_icon"
                src={close_icon}
                alt={"close icon"}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div id="htu_body">
        <div id="htu_content">
          <p id="htu_text">
            Veja abaixo como usar as ferramentas do nosso app!
          </p>
          <div id="htu_popup_buttons_row_div">
            <div id="htu_popup_buttons_column_div">
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(download_app_instructions);
                }}
              >
                <p id="htu_popup_button_text">Baixar o app</p>
              </div>
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(defina_rotina_instructions);
                }}
              >
                <p id="htu_popup_button_text">Defina a sua rotina</p>
              </div>
            </div>
            <div id="htu_popup_buttons_column_div">
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(best_route_instructions);
                }}
              >
                <p id="htu_popup_button_text">Melhor rota</p>
              </div>
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(error_report_instructions);
                }}
              >
                <p id="htu_popup_button_text">Informe um problema</p>
              </div>
            </div>
            <div id="htu_popup_buttons_column_div">
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(localize_seu_transporte_instructions);
                }}
              >
                <p id="htu_popup_button_text">Localize seu tranporte</p>
              </div>
              <div
                id="htu_popup_button"
                onClick={() => {
                  setShowPopup(favorite_routes_instructions);
                }}
              >
                <p id="htu_popup_button_text">Adicionar endereços favoritos</p>
              </div>
            </div>
          </div>
          <p id="htu_text">Continua com alguma dúvida?</p>
          <p id="htu_text">
            Veja abaixo as dúvidas mais frequentes dos nossos usuários!
          </p>
          <Faq tittle={"Sobre a Take"} section_content={section_content_1} />
          <Faq tittle={"Melhor Rota"} section_content={section_content_2} />
          <Faq
            tittle={"Localize seu transporte"}
            section_content={section_content_3}
          />
          <Faq
            tittle={"Defina sua rotina"}
            section_content={section_content_4}
          />
          <Faq
            tittle={"Informe um problema"}
            section_content={section_content_5}
          />
          <Faq
            tittle={"Adicione um endereço favorito"}
            section_content={section_content_6}
          />
          <p id="htu_text">
            Caso não tenha encontrado a resposta que procura entre em contato
            com a gente!
          </p>
        </div>
        <ContactForm />
      </div>
      <Download />
      <Footer />
    </>
  );
}
