import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const ModalResponse = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="modal-body">
        <h4 className="modal-body-h4">{props.title}</h4>
        <p className="modal-body-p">{props.text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-button" onClick={props.onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalResponse;
