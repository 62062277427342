import android_qr_code from "../../assets/qr-code-android.png";
import ios_qr_code from "../../assets/qr-code-ios.png"
import download_apple from "../../assets/appleStore.png";
import download_google from "../../assets/googleStore.png";
import "./download_popup.css";
const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

export default function Download_popup() {
  return (
    <div
      id="download_popup_background"
      style={{
        width: windowWidth,
        height: windowHeight,
        marginTop: windowHeight + 70,
        position: "absolute",
        marginLeft: -10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div id="download_popup_background_content">
        <div id="download_popup_column">
          <img
            id="download_popup_qr_code"
            src={android_qr_code}
          />
          <a
            href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img
              id="download_store_button"
              style={{ marginRight: 0 }}
              src={download_google}
              alt="Google_store"
            />
          </a>
        </div>
        <div id="download_popup_column">
        <img
            id="download_popup_qr_code"
            src={ios_qr_code}
          />
          <a
            href="https://apps.apple.com/br/app/take-a-bus/id6449265591"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img
              id="download_store_button"
              src={download_apple}
              style={{ marginRight: 0 }}
              alt="Apple_store"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
