import { useEffect } from "react";
import "./anuncieConosco.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import celPhone from "../../assets/img-celular.png";
import { Carousel } from "react-bootstrap";
import ContactForm from "../../components/contact-form/ContactForm";
import banner_example from "../../assets/banner_example.png"
import map_add_example from "../../assets/map_add_example.png"
import notification_add from "../../assets/notification_add_example.png"
import intersticial_example from "../../assets/intersticial_example.png"
import email_push_example from "../../assets/email_push_example.png"

const addTypes = [
  {
    type: "Banner",
    description:
      "Os Banners ficam no rodapé da tela 100% do tempo de uso do app",
    image: banner_example,
  },
  {
    type: "Anuncios intersticiais ",
    description:
      "Os Pop Ups aparecem de acordo com o tempo de uso do usuário. Os pop ups podem ter a duração de até 5 segundos",
    image: intersticial_example,
  },
  {
    type: "Pin no Mapa",
    description:
      "Com o Pin, o seu estabelecimento fica visível no mapa. Os usuários podem ver a localização do seu estabeleciomento se estiverem próximos ao local ou quando transitam pelo mapa",
    image: map_add_example,
  },
  {
    type: "Push email",
    description:
      "Com o Push, enviamos um email, junto com uma comunicação da Take, para o cliente. Nunca enviaremos um email puramente publicitário",
    image: email_push_example,
  },
  {
    type: "Push notificação",
    description:
      "Com o Push, enviamos uma notificação, junto com uma comunicação da Take, para o cliente. Nunca enviaremos uma notificação puramente publicitária",
    image: notification_add,
  },
];

const AnuncieConosco = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div>
      <Header />

      <div className="anuncie-main-div">
        <div className="anuncie-numbers-div">
          {/* <span className="anuncie-numbers-title">Dados de audiência</span>

          <div className="anuncie-numbers-info">
            <div className="anuncie-numbers-info-item">
              <span className="anuncie-number">+ 10.000</span>
              <span className="anuncie-number-ref">Acessos Diários</span>
            </div>
            <div className="anuncie-numbers-info-item">
              <span className="anuncie-number">+ 1.000.000</span>
              <span className="anuncie-number-ref">Viagens mensais</span>
            </div>
            <div className="anuncie-numbers-info-item">
              <span className="anuncie-number">+ 100.000</span>
              <span className="anuncie-number-ref">Downloads</span>
            </div>
          </div> */}
        </div>
      </div>

      <div className="add-models-div">
        <span className="anuncie-numbers-title">Tipos de anuncio</span>

        {addTypes.map((add, index) => (
          <div
            key={add.type}
            className="add-div"
            style={{ flexDirection: index % 2 === 0 ? "row-reverse" : "row" }}
          >
            <div className="img-div">
              <img className="image_add_example" alt="img banner img" src={add.image} />
            </div>

            <div className="add-text-div">
              <span className="add-text-div-title">{add.type}</span>
              <span className="add-text-div-p">{add.description}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="add-models-div-carousel">
        <span className="anuncie-numbers-title">Tipos de anuncio</span>
        <Carousel variant="dark" className="add-carousel" autoPlay={false}>
          {addTypes.map((add, index) => (
            <Carousel.Item>
              <div key={index} className="add-div">
                <div className="img-div">
                  <img className="image_add_example"  alt="img banner img" src={add.image} />
                </div>

                <div className="add-text-div">
                  <span className="add-text-div-title">{add.type}</span>
                  <span className="add-text-div-p">{add.description}</span>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <ContactForm hasCompany />

      <Footer />
    </div>
  );
};

export default AnuncieConosco;
