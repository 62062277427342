import { useState, useRef } from "react";
import "./header.css";
import menu from "../../assets/menu.svg";
import logo from "../../assets/logo.png";
import arrow from "../../assets/arrow-down.png";
import arrow_black from "../../assets/arrow-down-black.png";
import { Link } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import Download_popup from "../../components/download_popup/download_popup";
import close_icon from "../../assets/close_icon.svg";

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

// console.log("isIOS ? =>> ", isIOS, " isAndroid ? =>> ", isAndroid)

export default function Header({popupController}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownInfo, setDropdownInfo] = useState(null);
  const [dropdownSolucoes, setDropdownSolucoes] = useState(false);
  const [downloadPopup, setDownloadPopup] = useState(popupController ? true : false);
  const dropdownRef = useRef();

  return (
    <div id="background">
      {windowWidth >= 1030 ? (
        <>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img id="logo" src={logo} alt="logo" />
          </Link>

          <div id="navigation">
            <div
              id="dropdown_list_button"
              ref={dropdownRef}
              onClick={() => {
                setShowDropdown(!showDropdown);
                setDropdownInfo(dropdownRef.current?.getBoundingClientRect());
              }}
            >
              <p id="navigation_text">Soluções para você</p>
              <img id="arrow_logo" src={arrow} alt="arrow" />
            </div>
            {showDropdown ? (
              <div
                id="dropdown_background"
                style={{
                  left: dropdownInfo.left,
                  top: dropdownInfo.top + dropdownInfo.height,
                }}
              >
                <Link
                  to={"/localize-o-seu-transporte"}
                  style={{ textDecoration: "none" }}
                >
                  <div id="dropdown_content">
                    <p id="dropdown_text">Localize o seu transporte</p>
                  </div>
                </Link>
                <div id="dropdown_section_line" />
                <Link
                  to={"/escolha-a-melhor-rota"}
                  style={{ textDecoration: "none" }}
                >
                  <div id="dropdown_content">
                    <p id="dropdown_text">Escolha a melhor rota</p>
                  </div>
                </Link>
                <div id="dropdown_section_line" />
                <Link
                  to={"/defina-a-sua-rotina"}
                  style={{ textDecoration: "none" }}
                >
                  <div id="dropdown_content">
                    <p id="dropdown_text">Defina sua rotina</p>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
            <Link to={"/como-usar"} style={{ textDecoration: "none" }}>
              <p id="navigation_text">Como usar</p>
            </Link>
            <Link to={"/anuncie-no-app"} style={{ textDecoration: "none" }}>
              <p id="navigation_text">Anuncie no app</p>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div
            id="header_menu_mobile"
            ref={dropdownRef}
            onClick={() => {
              setShowDropdown(!showDropdown);
              setDropdownSolucoes(false);
              setDropdownInfo(dropdownRef.current?.getBoundingClientRect());
            }}
          >
            <img id="menu_icon" src={menu} alt="menu" />
          </div>
          {showDropdown ? (
            <div
              id="dropdown_background"
              style={{
                left: dropdownInfo.left,
                top: dropdownInfo.top + dropdownInfo.height,
              }}
            >
              <div
                id="dropdown_solucoes_para_voce"
                onClick={() => {
                  setDropdownSolucoes(!dropdownSolucoes);
                }}
              >
                <div id="dropdown_list_button">
                  <p id="dropdown_text">Soluções para você</p>
                  <img id="arrow_logo" src={arrow_black} alt="arrow" />
                </div>
                <div id="dropdown_section_line" />
                {dropdownSolucoes ? (
                  <>
                    <Link
                      to={"/localize-o-seu-transporte"}
                      style={{ textDecoration: "none" }}
                    >
                      <div id="dropdown_content_2">
                        <p id="dropdown_text">Localize o seu transporte</p>
                        <div id="dropdown_section_line" />
                      </div>
                    </Link>
                    <Link
                      to={"/escolha-a-melhor-rota"}
                      style={{ textDecoration: "none" }}
                    >
                      <div id="dropdown_content_2">
                        <p id="dropdown_text">Escolha a melhor rota</p>
                        <div id="dropdown_section_line" />
                      </div>
                    </Link>

                    <Link
                      to={"/defina-a-sua-rotina"}
                      style={{ textDecoration: "none" }}
                    >
                      <div id="dropdown_content_2">
                        <p id="dropdown_text">Defina sua rotina</p>
                      </div>
                    </Link>
                    <div id="dropdown_section_line" />
                  </>
                ) : (
                  <></>
                )}
                <Link to={"/como-usar"} style={{ textDecoration: "none" }}>
                  <div id="dropdown_content">
                    <p id="dropdown_text">Como usar</p>
                  </div>
                </Link>
                <div id="dropdown_section_line" />
                <Link to={"/anuncie-no-app"} style={{ textDecoration: "none" }}>
                  <div id="dropdown_content">
                    <p id="dropdown_text">Anuncie no app</p>
                  </div>
                </Link>
                <div id="dropdown_section_line" />
                <Link to={"/como-usar"} style={{ textDecoration: "none" }}>
                  <div id="dropdown_content">
                    <p id="dropdown_text">Ajuda</p>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div id="navigation">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <img id="logo" src={logo} alt="logo" />
            </Link>
          </div>
        </>
      )}
      <div id="button">
        {windowWidth >= 950 ? (
          <Link to={"/como-usar"} style={{ textDecoration: "none" }}>
            <p id="button_help">Ajuda</p>
          </Link>
        ) : (
          <></>
        )}
        {isIOS ? (
          <a href="" target="_blank" style={{ textDecoration: "none" }}>
            <div id="download_button">
              <p id="button_p">Baixe o App</p>
            </div>
          </a>
        ) : isAndroid ? (
          <a
            href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div id="download_button">
              <p id="button_p">Baixe o App</p>
            </div>
          </a>
        ) : (
          <div
            id="download_button"
            onClick={() => {
              setDownloadPopup(true);
            }}
          >
            <p id="button_p">Baixe o App</p>
          </div>
        )}
      </div>
      {downloadPopup ? (
        <>
          <Download_popup />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 120,
              position: "absolute"
            }}
          >
            <div
              id="popup_close_button"
              onClick={() => {
                setDownloadPopup(false);
              }}
            >
              <img
                id="popup_close_button_icon"
                src={close_icon}
                alt={"close icon"}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
