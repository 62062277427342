import "./popup.css";
import favorite_route from "../../assets/gif/rota-favorita.gif";
import best_route from "../../assets/gif/melhor-rota.gif";
import error_report from "../../assets/gif/error-report.gif";
import defina_rotina from "../../assets/gif/defina-rotina.gif"
import locate_bus from "../../assets/gif/localize-seu-transporte.gif"
import download_app from "../../assets/gif/download_app.gif"
import { useState } from "react";

const windowWidth = window.innerWidth;

export default function Popup({ tittle, gif_index, instructions }) {
  const [isSelected, setSelected] = useState(1);
  const gif = [favorite_route, best_route, error_report, locate_bus, defina_rotina, download_app];

  return (
    <div id="popup_background">
      <p id="popup_tittle">{tittle}</p>
      <div id="popup_content">
        <img id="popup_gif" src={gif[gif_index]} alt="gif explicativo" />
        {/* <img id="popup_gif" src={gif_url} alt="gif explicativo" /> */}
        <div id="popup_instructions">
          {instructions.map((item, index) => (
            <div id="popup_instrucions_item_background">
              <div
                id="popup_instrucions_index_background"
                onClick={() => {
                  console.log("selecionou o ", index + 1);
                  setSelected(index + 1);
                }}
                style={
                  windowWidth <= 550
                    ? isSelected === index + 1
                      ? { backgroundColor: "#f5aa22" }
                      : {
                          borderColor: "#f5aa22",
                          borderWidth: 3,
                          backgroundColor: "white",
                          borderStyle: "solid",
                        }
                    : null
                }
              >
                <p
                  id="popup_instructions_index_text"
                  style={
                    windowWidth <= 550
                      ? isSelected === index + 1
                        ? { color: "white" }
                        : { color: "#f5aa22" }
                      : null
                  }
                >
                  {index + 1}
                </p>
              </div>
              {windowWidth >= 550 ? (
                <p id="popup_instructions_item_text">{item}</p>
              ) : null}
            </div>
          ))}
        </div>
        {windowWidth >= 550 ? null : isSelected != null ? (
          <p id="popup_instructions_item_text">
            {instructions[isSelected - 1]}
          </p>
        ) : null}
      </div>
    </div>
  );
}