export const section_content_1 = [
  {
    tittle: "O que é a Take a Bus?",
    content: "Somos uma startup com a missão de revolucionar o transporte urbano, tornando-o mais seguro, descomplicado e amigável, através de integração de transportes e ferramentas inovadoras.",
  },
  {
    tittle: "Para que serve a Take a Bus",
    content: "A take a bus tem a função de melhorar a experiência de como você se locomove diariamente, com um mapeamento completo das linhas públicas de transporte e um conjunto de ferramentas pensadas a partir dos problemas enfrentados por utilizadores do transporte público.",
  }
];

export const section_content_2 = [
  {
    tittle: "Como a ferramenta funciona?",
    content: "Ao decidir sobre seu destino, o aplicativo mostra quais rotas estão disponíveis e qual a melhor rota para você, calculando desvantagens e vantagens como tempo, custo e segurança, conseguimos chegar no resultado de uma melhor rota.",
  },
  {
    tittle: "O que é a viagem por rota?",
    content: "Viagem por rota é seguir viagem com a melhor rota estipulada pela nossa inteligência, sob medida para você e suas prioridades.",
  },
];

export const section_content_3 = [
  {
    tittle: "Por que algumas rotas não oferrecem o rastreio?",
    content: "As informações de localização dos ônibus são fornecidas pelas próprias empresas de ônibus e algumas linhas ainda não estão equipadas com GPS.",
  },
  {
    tittle: "Por que o rastreio de ônibus é impreciso?",
    content: "A take a bus coleta os dados de localização e repassa aos usuários, não somos responsáveis pela manutenção do GPS integrados aos ônibus. Os erros de localização podem ocorrer por diversos motivos, como falta de sinal em determinados pontos, queda de internet ou falhas nos servidores das empresas responsáveis pelo repasse das informações.",
  },
  {
    tittle: "Para quais empresas o rastreio está disponível?",
    content: "Atualmente conseguimos rastrear os transportes das linhas gerenciadas pela  SPTrans.",
  },
];

export const section_content_4 = [
  {
    tittle: "Para que serve a ferramenta?",
    content: "A ferramenta tem como função evitar situações estressantes desnecessárias como atrasos ou desperdício de tempo e criar um dia a dia mais tranquilo, com menos estresse, mais foco e aumento na produtividade individual, gerando uma grande mudança em sua qualidade de vida e resultados.",
  },
  {
    tittle: "Como a ferramenta funciona?",
    content: "Você adiciona o endereço de origem, de destino e o horário do seu compromisso, a partir disso, calculamos o tempo do seu trajeto até a condução desejada e o tempo de chegada do transporte no ponto indicado, fornecendo alertas precisos, 15 minutos adiantados para que não ocorram imprevistos.",
  },
];

export const section_content_5 = [
  {
    tittle: "Para que serve a ferramenta?",
    content: "A opção de report de erros é um dos nossos maiores meios de comunicação com os nossos usuários, lá podemos mapear problemas que estejam acontecendo no aplicativo e corrigi-los o mais rápido possível.",
  },
  {
    tittle: "Como a ferramenta funciona?",
    content: "Ao enviar uma mensagem, um especialista vai notificar a equipe responsável sobre o problema, para que se resolva o mais rápido possível.",
  },
  {
    tittle: "O que deve ser enviado na mensagem?",
    content: "A ferramenta foi criada com o objetivo de ouvirmos como vocês gostariam que o app melhorasse, isso quer dizer que qualquer mensagem de melhoria é bem vinda! Desde uma falha no aplicativo à alguma sugestão de como o app pode ficar melhor para você.",
  },
];

export const section_content_6 = [
  {
    tittle: "Para que serve a ferramenta?",
    content: "Sempre que um endereço é adicionado aos favoritos vamos sugerir ele em um campo de origem ou destino, isso traz agilidade no seu dia a dia e tira aquele trabalho chato de todo dia digitar o mesmo endereço.",
  },
  {
    tittle: "Eu posso dar qualquer nome para um endereço?",
    content: "Sim! Você pode escolher o apelido daquele endereço da maneira que for mais fácil de se lembrar.",
  },
];


// gifs index
// rotas favoritas : 0
// melhor rota : 1
// report de erro : 2
// localize seu transporte : 3
// defina a rotina : 4
// download app : 5

export const favorite_routes_instructions = {
  tittle: "Rotas favoritas",
  gif_index: 0,
  instructions: [
    "Acesse a página 'Rotas Favoritas'",
    "Clique em 'Adicionar rota'",
    "Preenchha os campos de nome da rota e endereço e clique em salvar!"
  ],
};

export const best_route_instructions = {
  tittle: "Melhor rota",
  gif_index: 1,
  instructions: [
    "Preencha os cacmpos de origem e destino",
    "Clique em 'Viajar'",
    "Escolha umas das rotas sugeridas!"
  ],
};

export const error_report_instructions = {
  tittle: "Reporte de erros",
  gif_index: 2,
  instructions: [
    "Acesse a página 'Reportar erro'",
    "Preencha os campos de assunto e descrição com os detalhes do ocorrido",
    "Clique em 'Enviar' para avaliarmos o problema o quanto antes! "
  ],
};

export const localize_seu_transporte_instructions = {
  tittle: "Localize seu transporte",
  gif_index: 3,
  instructions: [
    "Acesse a play store e busque por Take a Bus",
    "Clique em 'Viajar' e escolha uma rota",
    "Caso a rota escolhida tenha um ônibus da SPTrans iremos representar a localização do veículo com o ícone de 'Estou a caminho!'"
  ],
};

export const defina_rotina_instructions = {
  tittle: "Definir uma rotina",
  gif_index: 4,
  instructions: [
    "Acesse a página 'Reportar erro'",
    "Preencha os campos de origem, destino e horario de chegada",
    "Escolha uma rota e clique em salvar",
    "Pronto! Agora é só esperar que vamos te notificar quando estiver próximo ao horário!"
  ],
};

export const download_app_instructions = {
  tittle: "Baixe o App",
  gif_index: 5,
  instructions: [
    "Acesse o nosso site",
    "Clique em 'Baixe o App'",
    "Clique em 'Instalar' já na loja de aplicativos",
    "Abra o app e aproveite todas as ferramentas que temos a oferecer!"
  ],
};