import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Download from "../../components/download_section/download";
import "./defina-a-sua-rotina.css";
import logoBranco from "../../assets/logo-branco.png";
import { isAndroid, isIOS } from "react-device-detect";
const windowWidth = window.innerWidth;

const DefinaASuaRotina = () => {
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header
        key={downloadPopup}
        popupController={firstRender ? false : true}
      />
      <div style={{ display: "flex", flexDirection: "column", marginTop: 70 }}>
        <div className="cel-div-rout">
          <div className="cel-div-first-rout">
            <div className="img-div-rout">
              <div className="alarm-div-rout">
                <div className="time-div-rout">
                  <span>09:00</span>
                </div>

                <div className="text-div-not-rout">
                  <strong>
                    <span>Trabalho</span>
                  </strong>
                  <span>Av. Paulista, 1765</span>
                  <span>Diário</span>
                </div>
              </div>
              <div className="alarm-div-rout">
                <div className="time-div-rout">
                  <span>11:00</span>
                </div>

                <div className="text-div-not-rout">
                  <strong>
                    <span>Curso</span>
                  </strong>
                  <span>Rua Brasília, 78</span>
                  <span>Diário</span>
                </div>
              </div>
              <div className="alarm-div-rout">
                <div className="time-div-rout">
                  <span>15:20</span>
                </div>

                <div className="text-div-not-rout">
                  <strong>
                    <span>Dentista</span>
                  </strong>
                  <span>Av. Paulista, 5000</span>
                  <span>Diário</span>
                </div>
              </div>
            </div>

            <div className="cel-text-div-rout">
              <p
                style={{ fontSize: "2rem", marginBottom: 0, paddingBottom: 0 }}
              >
                A Take te ajuda a seguir com a sua rotina
              </p>
              <div className="img-div-rout-mobile">
                <div className="alarm-div-rout">
                  <div className="time-div-rout">
                    <span>09:00</span>
                  </div>

                  <div className="text-div-not-rout">
                    <strong>
                      <span>Trabalho</span>
                    </strong>
                    <span>Av. Paulista, 1765</span>
                    <span>Diário</span>
                  </div>
                </div>
                <div className="alarm-div-rout">
                  <div className="time-div-rout">
                    <span>11:00</span>
                  </div>

                  <div className="text-div-not-rout">
                    <strong>
                      <span>Curso</span>
                    </strong>
                    <span>Rua Brasília, 78</span>
                    <span>Diário</span>
                  </div>
                </div>
                <div className="alarm-div-rout">
                  <div className="time-div-rout">
                    <span>15:20</span>
                  </div>

                  <div className="text-div-not-rout">
                    <strong>
                      <span>Dentista</span>
                    </strong>
                    <span>Av. Paulista, 5000</span>
                    <span>Diário</span>
                  </div>
                </div>
              </div>
              <p style={{ fontSize: "0.9rem", marginTop: 5 }}>
                Adicione compromissos na área de rotina e organize o seu dia a
                dia para levar o dia com mais leveza e traquilidade.
              </p>
            </div>
          </div>

          <div className="cel-div-second-rout">
            <div>
              <div className="cel-div-second-text-rout">
                <div>
                  {windowWidth >= 950 ? (
                    <></>
                  ) : (
                    <p
                      style={{
                        fontSize: "2rem",
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      A Take te avisa no horário certo para que você chegue a
                      tempo
                    </p>
                  )}
                  <div className="mobile-div-set-carrousel">
                    <div className="cel-div-second-text-paragraph-first-rout">
                      {windowWidth >= 950 ? (
                        <p
                          style={{
                            fontSize: "2rem",
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          A Take te avisa no horário certo para que você chegue
                          a tempo
                        </p>
                      ) : (
                        <></>
                      )}
                      <p style={{ fontSize: "0.9rem", marginTop: 10 }}>
                        Utilize a rotina da Take e passe mais tempo fazendo o
                        que ama.
                      </p>
                    </div>

                    <div className="bus-carrousel-div2-rout">
                      <div className="notification-div2-rout">
                        <div>
                          <div className="logo-small-div2-rout">
                            <img alt="logo take a bus" src={logoBranco} />
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "white",
                                  marginRight: 12,
                                }}
                              >
                                Take a Bus
                              </span>

                              <span style={{ fontSize: 8, color: "white" }}>
                                Há 15 min.
                              </span>
                            </div>

                            <span
                              style={{
                                fontSize: 10,
                                color: "white",
                                width: "80%",
                              }}
                            >
                              Seu ônibus chega em 15 minutos!
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="notification-div2-rout">
                        <div>
                          <div className="logo-small-div2-rout">
                            <img alt="logo take a bus" src={logoBranco} />
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "white",
                                  marginRight: 12,
                                }}
                              >
                                Take a Bus
                              </span>

                              <span style={{ fontSize: 8, color: "white" }}>
                                Há 8 min.
                              </span>
                            </div>

                            <span
                              style={{
                                fontSize: 10,
                                color: "white",
                                width: "80%",
                              }}
                            >
                              Seu ônibus chega em 10 minutos!
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="notification-div2-rout">
                        <div>
                          <div className="logo-small-div2-rout">
                            <img alt="logo take a bus" src={logoBranco} />
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "white",
                                  marginRight: 12,
                                }}
                              >
                                Take a Bus
                              </span>

                              <span style={{ fontSize: 8, color: "white" }}>
                                Há 5 min.
                              </span>
                            </div>

                            <span
                              style={{
                                fontSize: 10,
                                color: "white",
                                width: "80%",
                              }}
                            >
                              Seu ônibus chega em 5 minutos!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isIOS ? (
            <a href="https://apps.apple.com/br/app/take-a-bus/id6449265591" target="_blank" style={{ textDecoration: "none" }}>
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : isAndroid ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.takeabus.takeabus"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div className="download-app-rout" id="download_button_home">
                <p id="buttom_text_home">Baixe o app agora</p>
              </div>
            </a>
          ) : (
            <div
              className="download-app-rout"
              id="download_button_home"
              style={{ backgroundColor: "#f5aa22" }}
              onClick={() => {
                setDownloadPopup(!downloadPopup);
                setFirstRender(false);
              }}
            >
              <p id="buttom_text_home" style={{ color: "white" }}>
                Baixe o app agora
              </p>
            </div>
          )}
        </div>

        <div className="footer-div">
          <Download />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default DefinaASuaRotina;
